import React, { ReactElement } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import RootLayout from 'layouts/RootLayout';

const Statistics = dynamic(() => import('sections/HomePage/Statistics'));
const BenefitCard = dynamic(() => import('sections/HomePage/BenefitCard'));
// const MonthlyMeetings = dynamic(() => import('sections/HomePage/MonthlyMeetings'));
const Slider = dynamic(() => import('sections/HomePage/Slider'));
const TopArticles = dynamic(() => import('sections/HomePage/TopArticles'));

import { BenefitObjects } from 'sections/HomePage/BenefitObjects';
import { ITopArticlesCard } from 'sections/HomePage/TopArticles';

import { getMembersQuantity } from 'api/requests';
import { endpoints } from 'api/endpoints';
import proxyAxios from 'api/proxyAxios';

import useWindowSize from 'utils/hooks/useWindowSize';

import planetImg from '../../public/images/pages/Home/header-hero-planet-full-desktop.webp';
import aboutImg from '../../public/images/pages/Home/about-map_desktop.webp';
import home_banner from '../../public/images/pages/Home/home_banner.svg';
import GlobalFreightBannerImage from '../../public/images/pages/Home/banner_global-freight-summit.svg';
import logo1 from '../../public/images/pages/Home/home-slider__slide4-image.webp';

interface IProps {
  membersQuantity: number | string;
  articles: ITopArticlesCard[];
}

export default function HomePage({ membersQuantity, articles }: IProps) {
  const { width } = useWindowSize();

  const benefitCards = BenefitObjects.map((item: any) => {
    return (
      <BenefitCard
        key={item.id}
        heading={item.heading}
        description={item.description}
        icon={item.icon}
        classIdentifier={item.classIdentifier}
        linkUrl={item.linkUrl}
      />
    );
  });

  return (
    <div>
      {/* SECTION: HEAD */}
      <section className="new-section new-header-hero">
        {/* GLOBAL FREIGHT SUMMIT BANNER */}
        <div className="banner-global-freight">
          <div className="banner-global-freight__content">
            <div className="banner-global-freight__image-box">
              <Image
                className="banner-global-freight__image"
                src={GlobalFreightBannerImage}
                alt="global freight summit"
              />
            </div>
            <div className="banner-global-freight__description-box">
              <div className="banner-global-freight__title">
                Global Freight Summit  by DP World 18-20 November 2024
              </div>
              <div className="banner-global-freight__subtitle">
                Acting today to reach the opportunities of tomorrow. Dubai Exhibition Centre, Expo City, Dubai - UAE
              </div>
            </div>
            <div className="banner-global-freight__buttons-box">
              <button className="banner-global-freight__button-register">
                <Link
                  target="_blank"
                  href="https://www.theglobalfreightsummit.com/event/2024/register?rt=Y2OM2JipKUKQ_o7j_ZKkYQ"
                  rel="nofollow"
                >
                  Register
                </Link>
              </button>
            </div>
          </div>
        </div>

        <div className="new-container">
          <div className="new-header-hero__info-box">
            <h1 className="new-header-hero__heading">NVOCCS & FREIGHT FORWARDING NETWORK</h1>
            <p className="new-header-hero__description">
              Connecting global freight forwarders and NVOCCs. Our advanced ERP system enhances operational efficiency
              and drives growth. Join our freight network to expand your reach, optimize logistics, and stay competitive
              in the dynamic freight forwarding service industry.
            </p>
            <div className="new-header-hero__links-box">
              <Link className="new-link-block full" href="/membership">
                Register
              </Link>
              <Link className="new-link arrow" href="/company/contact-us">
                Show more
                <svg className="link-arrow directionRight" viewBox="0 0 10 10" aria-hidden="true">
                  <path className="link-arrow__line" d="M1 5h7" />
                  <path className="link-arrow__tip" d="M2 1l4 4-4 4" />
                </svg>
              </Link>
            </div>
            <Statistics membersQuantity={membersQuantity} />
          </div>
          <div className="new-header-hero__planet-box">
            <Image
              sizes="100vw"
              style={{
                width: '100%',
                height: 'auto',
              }}
              className="new-header-hero__planet"
              src={planetImg}
              alt="planet"
              priority={true}
            />
          </div>
        </div>

        {/* MONTHLY MEMBERS MEETING */}
        <div className="home-banner">
          <div className="content">
            <div className="image-wrapper">
              <Image
                sizes="100vw"
                style={{
                  width: '100%',
                  height: 'auto',
                }}
                className=""
                src={home_banner}
                alt=""
              />
            </div>
            <div className="title">
              The next Monthly Members’ Meeting will take place on October 24, 2024, at 13 p.m. Dubai time on Zoom.
            </div>
            <div className="subtitle">
              This month's speaker is
              <span className="new-text--bold"> Atif Rafiq, Director Marketing & Sales at Al Sharqi Shipping</span>,
              our premium member in UAE.
              Mr. Atif will present his company's services and experience, answer all questions and discuss industry issues.
              {/*<span className="new-text--bold"></span>*/}
            </div>

            <button className="register">
              <Link
                target="_blank"
                href="https://crmmarketing.powerappsportals.com/dfallianceinvitepage/"
                rel="nofollow"
              >
                Register
              </Link>
            </button>
          </div>
        </div>
      </section>

      {/*<section className="new-section members-meeting">*/}
      {/*  <div className="new-container">*/}
      {/*    <MonthlyMeetings />*/}
      {/*  </div>*/}
      {/*</section>*/}

      {/* SECTION: ABOUT US */}
      <section className="new-section new-about">
        <div className="new-container">
          <div className="new-about__map-box">
            <Image
              sizes="100vw"
              style={{
                width: '100%',
                height: 'auto',
              }}
              className="new-about__map"
              src={aboutImg}
              alt="map"
            />
          </div>
          <div className="new-about__info-box">
            <h2 className="new-about__heading">About us</h2>
            <div className="new-about__description-box">
              <p className="new-about__description-paragraph">
                Digital Freight Alliance Network was founded in 2020 by DP World. Four years later and we are a freight
                network of over <span className="new-text--bold">7,000</span> members.
              </p>
              <p className="new-about__description-paragraph">
                The Alliance network is an independent freight forwarders’ association covering more than{' '}
                <span className="new-text--bold">190</span> countries.
              </p>
              <p className="new-about__description-paragraph">
                We provide members with web-based tools, networking and commercial opportunities across sea, air, and
                land.
              </p>
              <p className="new-about__description-paragraph">
                Sign up for a wide range of benefits and opportunities that can be found here for freight forwarders.
              </p>
            </div>
            <div className="new-about__links-box">
              <Link className="new-link-block dark" href="/membership">
                Membership plans
              </Link>
              <Link className="new-link" href="/resources/terms-and-conditions">
                View terms and conditions
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* SECTION: VIDEO */}
      <section className="new-section video">
        <img className="video__bg-bubbles" src="/images/pages/Home/video-section_bg-bubbles.svg" alt="bubbles" />
        <div className="new-container">
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'VideoObject',
                name: 'Global Freight Network: International Freight Forwarding Service | DFA',
                description:
                  'International freight forwarding services. Become a member of DFA & join our freight network to access dynamic forwarders network & streamlined logistics.',
                thumbnailUrl: ['https://www.df-alliance.com/images/pages/Home/video-section_poster.webp'],
                uploadDate: '2024-02-06T08:00:00+08:00',
                duration: 'PT00H01M06S',
                contentUrl: 'https://www.df-alliance.com/video/dfa_cargoes_sr_trade.mp4',
              }),
            }}
          />
          {width > 767 ? (
            <div className="video__outer-box">
              <video className="video__player" poster="/images/pages/Home/video-section_poster.webp" controls>
                <source src="/video/dfa_cargoes_sr_trade.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          ) : (
            <video className="video__player" poster="/images/pages/Home/video-section_poster.webp" controls>
              <source src="/video/dfa_cargoes_sr_trade.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      </section>

      {/* SECTION: BENEFITS */}
      <section className="new-section new-benefits">
        <div className="new-container">
          <div className="new-benefits__info-box">
            <h2 className="new-benefits__heading">Freight Network Benefits</h2>
            <p className="new-benefits__description">
              Digital Freight Alliance Network provides benefits to support association members to get ahead:
            </p>
          </div>
          <div className="new-benefits__cards-box">{benefitCards}</div>

          <div className="new-benefits__services-box">
            <div className="new-benefits__service">
              <div className="new-benefits__service-icon-box">
                <Image
                  width={15}
                  height={15}
                  className="new-benefits__service-icon"
                  src="/images/pages/Home/services_icon-star.svg"
                  alt="your-business"
                />
              </div>
              <p className="new-benefits__service-description">
                Associate your business with a leading enabler of freight forwarding logistics and trade
              </p>
            </div>
            <div className="new-benefits__service">
              <div className="new-benefits__service-icon-box">
                <Image
                  width={16}
                  height={16}
                  className="new-benefits__service-icon"
                  src="/images/pages/Home/services_icon-tools.svg"
                  alt="your-business"
                />
              </div>
              <p className="new-benefits__service-description">Web-based tools for freight forwarders</p>
            </div>
            <div className="new-benefits__service">
              <div className="new-benefits__service-icon-box">
                <Image
                  width={16}
                  height={16}
                  className="new-benefits__service-icon"
                  src="/images/pages/Home/services_icon-access.svg"
                  alt="your-business"
                />
              </div>
              <p className="new-benefits__service-description">Access to other DP World products</p>
            </div>
            <div className="new-benefits__service">
              <div className="new-benefits__service-icon-box">
                <Image
                  width={18}
                  height={16}
                  className="new-benefits__service-icon"
                  src="/images/pages/Home/services_icon-opportunities.svg"
                  alt="your-business"
                />
              </div>
              <p className="new-benefits__service-description">
                Incremental business opportunities through our freight network
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* SECTION: SLIDER */}
      {/*<Slider />*/}
      <div className="home-slider__slide slide2 slide4">
        <div className="container">
          <div className="home-slider__slide-content-box">
            <div className="home-slider__slide-description">
              <div className="home-slider__slide-title">Cutting edge digital logistics tools</div>
              <p className="home-slider__slide-text">
                With SeaRates freight forwarders are able to get access to the ultimate ecosystem of applications such
                as rate benchmarking, cargo tracking, route optimization, schedules, paperwork and more! All of this is
                available with SaaS access, API and white-label web integrations.
              </p>
              {/*<Link className="home-slider__slide-link" href="/benefits/digital-tools">*/}
              {/*  Show more*/}
              {/*  <svg className="c-arrow c-arrow_directionRight" viewBox="0 0 10 10" aria-hidden="true">*/}
              {/*    <path className="c-arrow__line" d="M1 5h7" />*/}
              {/*    <path className="c-arrow__tip" d="M2 1l4 4-4 4" />*/}
              {/*  </svg>*/}
              {/*</Link>*/}
              <Link className="new-link-block full" href="/membership">
                Register
              </Link>
            </div>
            <div className="home-slider__slide-image-box">
              <Image
                sizes="100vw"
                style={{
                  width: '100%',
                  height: 'auto',
                }}
                className="home-slider__slide-image"
                src={logo1}
                alt="terminals"
              />
            </div>
          </div>
        </div>
      </div>

      {/* SECTION: TOP ARTICLES */}
      <section className="new-section latest-news">
        <div className="new-container">
          <TopArticles articles={articles} />
        </div>
      </section>

      {/* SECTION: SEO INFO */}
      <section className="new-section__seo-info">
        <div className="new-container">
          <p>
            Digital Freight Alliance is a network of freight forwarders, spearheaded by industry giant DP World.
            With a global presence spanning 190 countries, DF ALLIANCE swiftly rose to prominence,
            boasting a membership exceeding 7,000 freight forwarders worldwide.
          </p>
          <p>
            DF Alliance is not merely a platform; it's a transformative force in the realm of freight
            forwarding networks. At its core, DF Alliance serves as a digital hub where freight forwarders
            converge to unlock a world of possibilities. Upon membership, forwarders gain access to a suite of
            cutting-edge tools tailored to streamline their operations and bolster efficiency.
          </p>
          <p>
            At the heart of DF Alliance lies its commitment to empowering international freight forwarders.
            In a landscape defined by rapid globalization, DF Alliance equips forwarders with the tools they need
            to thrive on the global stage. Through our comprehensive network, forwarders seamlessly connect with
            partners worldwide, forging alliances that transcend borders and drive mutual success.
          </p>
          <p>
            What sets DF Alliance apart is its unwavering dedication to innovation. As a forward-thinking platform,
            DF Alliance continually evolves to meet the evolving needs of the industry. From pioneering digital
            solutions to embracing emerging technologies, DF Alliance remains at the forefront of the freight
            forwarding revolution.
          </p>
          <p>
            In the fast-paced world of global forwarding, success hinges on collaboration and connectivity.
            DF Alliance serves as the bridge that unites forwarders, fostering a community built on trust,
            reliability, and mutual growth. Together, we navigate the complexities of international trade,
            propelling businesses to new heights of success.
          </p>
        </div>
      </section>
    </div>
  );
}

export const getStaticProps = async () => {
  const HOUR = 60 * 60;
  const meta = {
    title: 'Freight Forwarders | International Freight Forwarding Network - DF Alliance',
    description:
      'International freight forwarders network. DF Alliance is a freight network that onboards partners providing freight forwarding services. Become a member today!',
    path: '/',
  };

  try {
    const data = await getMembersQuantity();

    const { data: articles } = await proxyAxios(endpoints.blog.previewArticles + '?limit=3');

    return { props: { membersQuantity: data.data.accounts, articles, ...meta }, revalidate: HOUR * 3 };
  } catch (e) {
    console.log('endpoints.membersQuantity', e);
  }

  return { props: { membersQuantity: 0, articles: [], ...meta }, revalidate: HOUR * 3 };
};

HomePage.getLayout = function getLayout(page: ReactElement) {
  return <RootLayout isHeaderTempFix>{page}</RootLayout>;
};
